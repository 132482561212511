import React, { useRef } from 'react';
import ModalLayoutSelection from '../ModalLayoutSelection';
import styles from './AddressInformationModal.scss';
import AddressInformation from '../AddressInformation';
import AddressInformationType from '../AddressInformation/AddressInformation';
import dataHooks from '../../data-hooks';
import { useBi, useTranslation } from 'yoshi-flow-editor-runtime';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import { ChangeLocationPayload } from '../../../../state/session/session.actions.types';
import { ActionCreator } from 'redux';
import Spinner from '../Spinner';

export interface AddressInformationModalProps {
  isMobile: boolean;
  onRequestClose: () => void;
  isMultiLocation?: boolean;
  isFormValid: boolean;
  changeLocation: (payload: ChangeLocationPayload) => void;
  revertAddress: ActionCreator<void>;
  isLoadingLocationFromServer: boolean;
  isLoadingLocationsSettingsFromServer: boolean;
  isAddressSelectionModalOpen: boolean;
}
type AddressInformationRef = React.ElementRef<typeof AddressInformationType>;

const AddressInformationModal: React.FC<AddressInformationModalProps> = ({
  isMobile,
  onRequestClose,
  isMultiLocation,
  changeLocation,
  isFormValid,
  isLoadingLocationFromServer,
  isLoadingLocationsSettingsFromServer,
  isAddressSelectionModalOpen,
  revertAddress,
}) => {
  const { t } = useTranslation();
  const addressInformationRef = useRef<AddressInformationRef>(null);
  const biLogger = useBi();

  function handleOkClick() {
    // we are calling the internal submit method of AddressInformation component so we don't have to duplicate it.
    // this code should be deprecated once we close the multi location experiment

    // eslint-disable-next-line no-unused-expressions
    addressInformationRef.current?.submit();
  }

  function onSubmit(locationId: string) {
    if (isMultiLocation) {
      changeLocation({ locationId });
    }
    onRequestClose();
  }

  function handleRequestClose() {
    onRequestClose();
    revertAddress();
  }

  const footer = (
    <ModalLayoutBasicFooter
      isMobile={isMobile}
      onOkClick={handleOkClick}
      onCancelClick={() => {
        biLogger.closeDispatchSettings({ close_type: 'cancel' });
        handleRequestClose();
      }}
      isLoading={isLoadingLocationFromServer}
      isSaveBtnDisabled={!isFormValid}
      fullWidthMobile
    />
  );

  return (
    <ModalLayoutSelection
      scrollable
      header={t('order_settings_modal_title')}
      footer={footer}
      onCloseClick={() => {
        biLogger.closeDispatchSettings({ close_type: 'exit' });
        handleRequestClose();
      }}
      data-hook={dataHooks.addressInformationModal}
      className={isAddressSelectionModalOpen ? styles.hideModal : ''}
    >
      {isLoadingLocationsSettingsFromServer ? (
        <div className={styles.spinnerWrapper}>
          <Spinner data-hook={dataHooks.loadingLocationsSettingsSpinner} />
        </div>
      ) : (
        <AddressInformation viewedFrom="multi-location-modal" ref={addressInformationRef} onSubmit={onSubmit} />
      )}
    </ModalLayoutSelection>
  );
};

AddressInformationModal.displayName = 'AddressInformationModal';

export default AddressInformationModal;
